<template>
    <div class="container">
        <div class="nav">
            <!-- <div class="left">
                <i class="el-icon-back" @click="routerChange(1)"></i>
                返回
            </div> -->
        </div>
        <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true"
            @play="onPlayerPlay($event)"
            @pause="onPlayerPause($event)"
            @ended="onPlayerEnded($event)"
            @loadeddata="onPlayerLoadeddata($event)"
            @waiting="onPlayerWaiting($event)"
            @playing="onPlayerPlaying($event)"
            @timeupdate="onPlayerTimeupdate($event)"
            @canplay="onPlayerCanplay($event)"
            @canplaythrough="onPlayerCanplaythrough($event)"
            @ready="playerReadied"
            @statechanged="playerStateChanged($event)"
        >
        </video-player>
    </div>
</template>

<script>

export default {
    components: {},
    props: {
        sourceSrc: {
            type: String
        }
    },
    data() {
        return {
            playerOptions: {
                width: '1080px',
                height: '463px',
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                // fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    {
                        type: 'video/mp4', // 类型
                        src: this.sourceSrc // url地址
                    }
                ],
                poster: '', // 封面地址
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                }
            }
        };
    },
    watch: {},
    computed: {
        player() {
            return this.$refs.videoPlayer.player;
        }
    },
    mounted() {
        setTimeout(() => {
            console.log('dynamic change options', this.player);
            this.player.muted(false);
        }, 5000);
    },
    methods: {
        routerChange(key, q = '') {
            switch (key) {
                case 1:
                    this.$router.go(-1);
                    break;
            }
        },
        // listen event
        onPlayerPlay(player) {
            // console.log('player play!', player)
        },
        onPlayerPause(player) {
            // console.log('player pause!', player)
        },
        onPlayerEnded(player) {
            // console.log('player ended!', player)
        },
        onPlayerLoadeddata(player) {
            // console.log('player Loadeddata!', player)
        },
        onPlayerWaiting(player) {
            // console.log('player Waiting!', player)
        },
        onPlayerPlaying(player) {
            // console.log('player Playing!', player)
        },
        onPlayerTimeupdate(player) {
            // console.log('player Timeupdate!', player.currentTime())
        },
        onPlayerCanplay(player) {
            // console.log('player Canplay!', player)
        },
        onPlayerCanplaythrough(player) {
            // console.log('player Canplaythrough!', player)
        },
        // or listen state event
        playerStateChanged(playerCurrentState) {
            // console.log('player current update state', playerCurrentState)
        },
        // player is ready
        playerReadied(player) {
            // seek to 10s
            console.log('example player 1 readied', player);
            player.currentTime(10);
            // console.log('example 01: the player is readied', player)
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    .nav {
        margin: 18px 0;
        font-weight: 500;
        color: rgba(50, 53, 56, 1);
        @include flex(row, space-between, center);
        // margin-bottom: 100px;
        .left {
            font-size: 20px;
            @include ftb;
            .el-icon-back {
                font-size: 24px;
                color: #1D517E;
                font-weight: 800;
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
}
</style>
