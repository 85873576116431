<template>
    <div class="container">
        <div class="play">
            <my-player v-if="source" :sourceSrc="source"/>
            <iframe v-if="videoSrc" :src="videoSrc" frameborder="0"></iframe>
        </div>
    </div>
</template>

<script>
import MyPlayer from '@/components/common/MyPlayer'

export default {
    components: {
        MyPlayer
    },
    props: {},
    data() {
        return {
            source: '',
            videoSrc: ''
        }
    },
    watch: {},
    computed: {},
    created() {
        this.source = this.$route.query.source
        this.videoSrc = this.$route.query.videoSrc
    },
    mounted() {},
    methods: {
    }
};
</script>
<style lang="scss" scoped>
.container{
    width: 1080px;
    margin: 0 auto;
}
</style>